import React from "react";
import styled from "styled-components";
import { textInputStyles } from "../globalStyles";

const InputLabel = styled.label`
  display: block;
  font-family: "Noa", sans-serif;
  text-align: left;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${textInputStyles};
`;

const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <>
      {props["aria-label"] && (
        <InputLabel htmlFor={props.name}>{props["aria-label"]}</InputLabel>
      )}
      <StyledInput {...props} />
    </>
  );
};

export default Input;
