import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`;

const Input = styled.input`
  accent-color: var(--blue);
  transform: scale(1.5) translate(2px, 1px);
  width: 80px;

  @media (hover: hover) and (pointer: fine) {
    width: unset;
  }
`;

const Label = styled.label`
  margin-inline-start: 20px;
  max-width: calc(100% - 40px);
`;

const Checkbox = ({
  className,
  children,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Container className={className}>
      <Input type="checkbox" {...props} />
      <Label htmlFor={props.id}>{children}</Label>
    </Container>
  );
};

export default Checkbox;
