export const MONTHS = [
  { id: "01", en: "January", nl: "Januari", fr: "Janvier" },
  { id: "02", en: "February", nl: "Februari", fr: "Février" },
  { id: "03", en: "March", nl: "Maart", fr: "Mars" },
  { id: "04", en: "April", nl: "April", fr: "Avril" },
  { id: "05", en: "May", nl: "Mei", fr: "Mai" },
  { id: "06", en: "June", nl: "Juni", fr: "Juin" },
  { id: "07", en: "July", nl: "Juli", fr: "Juillet" },
  { id: "08", en: "August", nl: "Augustus", fr: "Août" },
  { id: "09", en: "September", nl: "September", fr: "Septembre" },
  { id: "10", en: "October", nl: "Oktober", fr: "Octobre" },
  { id: "11", en: "November", nl: "November", fr: "Novembre" },
  { id: "12", en: "December", nl: "December", fr: "Décembre" },
];
