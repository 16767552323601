import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";
import {
  textInputStyles,
  inputLabelStyles,
  device,
  bodyLargeStyles,
} from "../globalStyles";

import { MONTHS } from "../constants/months";

import { useLangContext } from "../context/langContext";

const StyledWrapper = styled.div`
  width: 125%;
  & .react-autosuggest__input {
    ${textInputStyles};
    &:focus-visible {
      outline: none;
      border-color: var(--dark-blue-3);
    }
  }

  & .react-autosuggest__input--open {
    border-bottom: none;
  }

  & .react-autosuggest__suggestion {
    border: solid 1px var(--dark-blue-3);

    &:not(:first-child) {
      border-top: none;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background: var(--light-blue);
  }

  & .react-autosuggest__container--open {
    position: relative;
  }

  & .react-autosuggest__suggestions-container {
    position: absolute;
    background: white;
    width: 100%;
    top: calc(100% - 1px);
    z-index: 2;
    min-width: 16rem;
  }
`;

const Label = styled.label`
  ${inputLabelStyles};
`;

const Option = styled.p`
  ${bodyLargeStyles};
  padding: 0.8rem 1.6rem;
  text-align: left;

  &:hover {
    background: var(--light-blue);
  }

  @media (min-height: 670px) and (max-width: 1439px),
    (min-height: 700px) and (${device.minLaptopL}) {
    padding: 1.6rem;
  }

  @media (${device.minTablet}) and (min-height: 670px) and (max-width: 1439px) {
    padding: 16px 28px;
  }
`;

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, lang) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? MONTHS
    : MONTHS.filter(
        (month) =>
          month[lang]
            .replace(/é/, "e")
            .replace(/û/, "u")
            .toLowerCase()
            .includes(inputValue) || month.id.includes(inputValue)
      );
};

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion, lang) => (
  <Option>{suggestion[lang]}</Option>
);

const MonthAutoSuggest = (props) => {
  const { lang } = useLangContext();

  const [value, setValue] = useState(
    MONTHS?.[parseInt(props.defaultValue, 10) - 1]?.[lang] || ""
  );
  const [savedValue, setSavedValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [savedMonthCode, setSavedMonthCode] = useState(
    props.defaultValue || ""
  );

  const onChange = (event, other) => {
    const { newValue } = other;
    setValue(newValue);
    setSavedValue((savedValue) => {
      if (newValue !== savedValue) {
        setSavedMonthCode("");
      }
      return savedValue;
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, lang));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSavedMonthCode((monthCode) => {
      setValue((value) => {
        if (!value) {
          setSavedMonthCode("");
          return "";
        }
        return value;
      });
      return monthCode;
    });

    setSuggestions([]);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    setSavedMonthCode(suggestion.id);
    setSavedValue(suggestion[lang]);
    return suggestion[lang];
  };

  const handleBlur = () => {
    if (!value) return;
    if (value.length > 2) return;
    const code = parseInt(value, 10);
    if (code && code <= 12) {
      setSavedMonthCode(value);
    }
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    value,
    onChange,
    onBlur: handleBlur,
    required: props.required,
    ...props.inputProps,
  };

  useEffect(() => {
    props?.onChange(savedMonthCode);
  }, [savedMonthCode]);

  // Finally, render it!
  return (
    <StyledWrapper className={props.className}>
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(s) => renderSuggestion(s, lang)}
        inputProps={inputProps}
        id={props.id}
        shouldRenderSuggestions={() => true}
      />
    </StyledWrapper>
  );
};

export default MonthAutoSuggest;
