import React, { useState } from "react";
import styled from "styled-components";

import ClickableDiv from "./ClickableDiv";

import Info from "../svg/info.svg";

const Container = styled.div`
  position: relative;
`;

const StyledCD = styled(ClickableDiv)`
  display: flex;
`;

const Tooltip = styled.div`
  cursor: auto;
  position: absolute;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  background: var(--dark-blue-3);
  color: white;
  border-radius: 2px;
  width: 275px;
  padding: 8px;
  text-align: left;
  font-family: Roboto, sans-serif;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 95%) scaleX(0.8);
    border: 8px solid;
    border-color: var(--dark-blue-3) transparent transparent transparent;
  }
`;

type InfoTooltipType = {
  className?: string;
  children: React.ReactNode;
};

const InfoTooltip = ({ className, children }: InfoTooltipType) => {
  const [show, setShow] = useState(false);

  return (
    <Container
      className={className}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <StyledCD onClick={() => setShow((s) => !s)}>
        <Info />
      </StyledCD>
      {show && <Tooltip>{children}</Tooltip>}
    </Container>
  );
};

export default InfoTooltip;
