import React from "react";
import styled, { css } from "styled-components";

const animationDuration = 1.2;

const rotateAfters = (total) => {
  let styles = "";
  for (let i = 1; i <= total; i += 1) {
    styles += `
      & > div:nth-child(${i}) {
        transform: rotate(${(i - 1) * (360 / total)}deg);
        animation-delay: ${
          ((total - i) *
            -1 *
            Math.round((animationDuration / total + Number.EPSILON) * 1000)) /
          1000
        }s;
      } 
    `;
  }

  return css`
    ${styles}
  `;
};

const StyledSpinner = styled.div<{
  color?: string;
  height?: number;
  width?: number;
  total?: number;
}>`
  color: ${({ color = "white" }) => color};
  height: ${({ height = 24 }) => height + "px"};
  width: ${({ width = 24 }) => width + "px"};
  display: block;
  position: relative;

  & > div {
    transform-origin: ${({ width = 24, height = 24 }) =>
      `${width / 2}px ${height / 2}px`};
    animation: lds-spinner ${animationDuration}s linear infinite;
  }

  & > div:after {
    content: " ";
    display: block;
    position: absolute;
    ${({ width = 24 }) =>
      `                                       // Original values for a 80x80 loader. Unfortunately it doesnt seem to be really linear
        top: calc((${width} / 80) * 0px);     // top: 3px;
        left: calc((${width} / 80) * 37px);   // left: 37px;
        width: calc((${width} / 80) * 8px);   // width: 6px;
        height: calc((${width} / 80) * 24px); // height: 18px;
      `};
    border-radius: 20px;
    background: #fff;
  }

  ${({ total = 8 }) => rotateAfters(total)};

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

type LoaderProps = {
  color?: string;
  height?: number;
  width?: number;
  total?: number;
};

// https://loading.io/css/
const Spinner = ({ total = 8, ...props }: LoaderProps) => {
  return (
    <StyledSpinner {...props} total={total}>
      {new Array(total).fill(0).map((x, i) => (
        <div key={i} />
      ))}
    </StyledSpinner>
  );
};

export default Spinner;
